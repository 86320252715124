import React, { Suspense, lazy } from 'react';
import DashboardLayout from '../Layouts/DashboardLayout';
import Loader from '../components/Loader';
import { Outlet } from 'react-router-dom';
import PrivateLayout from '../Layouts/PrivateLayout';

// Lazy import the components
const Users = lazy(() => import('../pages/Admin/Users'));  
const Home = lazy(() => import('../pages/Admin/Home'));  

const adminRoutes = [
    {
        path: '/',
        element: (
            <Suspense fallback={<Loader />}>
                <DashboardLayout>
                    <Outlet />
                </DashboardLayout>
            </Suspense>
        ),
        children: [
            { path: '/', element: <Suspense fallback={<Loader />}><Home /></Suspense> },  
            { path: '/admin/users', element: <Suspense fallback={<Loader />}><Users /></Suspense> } 
        ]
    },

];
 
export default [
    {
        path: '/',
        element: <PrivateLayout />,
        children: [...adminRoutes]
    }
]






