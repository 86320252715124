import React, { Suspense, lazy } from 'react';
import DashboardLayout from '../Layouts/DashboardLayout';
import Loader from '../components/Loader';
import { Outlet } from 'react-router-dom';

// Lazy import the components
const Overview = lazy(() => import('../pages/Dashboard/Overview'));
const Home = lazy(() => import('../pages/Dashboard/Home'));
const DashboardCareer = lazy(() => import('../pages/Dashboard/DashboardCareer'));
const DashboardCertifications = lazy(() => import('../pages/Dashboard/DashboardCertifications'));
const Community = lazy(() => import('../pages/Dashboard/Community'));
const Events = lazy(() => import('../pages/Dashboard/Events'));
const Help = lazy(() => import('../pages/Dashboard/Help'));
const Resources = lazy(() => import('../pages/Dashboard/Resources'));
const Settings = lazy(() => import('../pages/Dashboard/Settings'));
const Summary = lazy(() => import('../pages/Dashboard/Summary'));
const SearchCareer = lazy(() => import('../pages/Dashboard/Find/SearchCareer'));
const BrowseCareer = lazy(() => import('../pages/Dashboard/Find/BrowseCareer'));
const LocalTraining = lazy(() => import('../pages/Dashboard/Profile/LocalTraining'));
const Certifications = lazy(() => import('../pages/Dashboard/Profile/Certifications'));
const Licenses = lazy(() => import('../pages/Dashboard/Profile/Licenses'));
const State = lazy(() => import('../pages/Dashboard/Profile/State'));
const Bright = lazy(() => import('../pages/Dashboard/Find/Bright'));
const CareerCluster = lazy(() => import('../pages/Dashboard/Find/CareerCluster'));
const Zone = lazy(() => import('../pages/Dashboard/Find/Zone'));
const LocalJobs = lazy(() => import('../pages/Dashboard/Profile/LocalJobs'));
const Contact = lazy(() => import('../pages/Dashboard/Contact'));
const LocalSalary = lazy(() => import('../pages/Dashboard/Profile/LocalSalary'));
const SortedByInterest = lazy(() => import('../pages/Dashboard/Find/SortedByInterest'));
const WelcomePage = lazy(() => import('../pages/WelcomePage'));
const SetUpProfile = lazy(() => import('../pages/SetUpProfile'));
const Profiler = lazy(() => import('../pages/Profiler'))
const PrintProfiler = lazy(() => import('../pages/Profiler/PrintProfiler'))
const ShareProfilerPage = lazy(() => import('../pages/Profiler/PrintProfiler/ShareProfilerPage'))
const PrivateLayout = lazy(() => import('../Layouts/PrivateLayout'))

const DashboardRoutes = [
    {
        path: '/',
        element: (
            <Suspense fallback={<Loader />}>
                <DashboardLayout>
                    <Outlet />
                </DashboardLayout>
            </Suspense>
        ),
        children: [
            { path: '/', element: <Suspense fallback={<Loader />}><Home /></Suspense> },
            { path: '/dashboard/overview', element: <Suspense fallback={<Loader />}><Overview /></Suspense> },
            { path: '/dashboard/resources', element: <Suspense fallback={<Loader />}><Resources /></Suspense> },
            { path: '/dashboard/settings', element: <Suspense fallback={<Loader />}><Settings /></Suspense> },
            { path: '/dashboard/career', element: <Suspense fallback={<Loader />}><DashboardCareer /></Suspense> },
            { path: '/dashboard/certifications', element: <Suspense fallback={<Loader />}><DashboardCertifications /></Suspense> },
            { path: '/dashboard/community', element: <Suspense fallback={<Loader />}><Community /></Suspense> },
            { path: '/dashboard/events', element: <Suspense fallback={<Loader />}><Events /></Suspense> },
            { path: '/dashboard/help', element: <Suspense fallback={<Loader />}><Help /></Suspense> },
            { path: '/profile/summary/:code', element: <Suspense fallback={<Loader />}><Summary /></Suspense> },
            { path: '/find/browse', element: <Suspense fallback={<Loader />}><BrowseCareer /></Suspense> },
            { path: '/find/search', element: <Suspense fallback={<Loader />}><SearchCareer /></Suspense> },
            { path: '/profile/localtraining/:code', element: <Suspense fallback={<Loader />}><LocalTraining /></Suspense> },
            { path: '/find/bright', element: <Suspense fallback={<Loader />}><Bright /></Suspense> },
            { path: '/find/zone', element: <Suspense fallback={<Loader />}><Zone /></Suspense> },
            { path: '/find/sorted-by-interest', element: <Suspense fallback={<Loader />}><SortedByInterest /></Suspense> },
            { path: '/profile/certifications/:code', element: <Suspense fallback={<Loader />}><Certifications /></Suspense> },
            { path: '/profile/state/:code', element: <Suspense fallback={<Loader />}><State /></Suspense> },
            { path: '/profile/local-jobs/:code', element: <Suspense fallback={<Loader />}><LocalJobs /></Suspense> },
            { path: '/profile/local-salary/:code', element: <Suspense fallback={<Loader />}><LocalSalary /></Suspense> },
            { path: '/profile/licenses/:code', element: <Suspense fallback={<Loader />}><Licenses /></Suspense> },
            { path: '/find/cluster', element: <Suspense fallback={<Loader />}><CareerCluster /></Suspense> },
            { path: 'dashboard/contact', element: <Suspense fallback={<Loader />}><Contact /></Suspense> }
        ]
    },

];

const WithoutLayoutRoutes = [
    { path: 'welcome', element: <Suspense fallback={<Loader />}><WelcomePage /></Suspense> },
    { path: '/profiler', element: <Suspense fallback={<Loader />}><Profiler /></Suspense> },
    { path: '/print/:type', element: <Suspense fallback={<Loader />}><PrintProfiler /></Suspense> },
    { path: '/share/:type', element: <Suspense fallback={<Loader />}><ShareProfilerPage /></Suspense> },
    { path: '/setup-profile', element: <Suspense fallback={<Loader />}><SetUpProfile /></Suspense> },
]

export default [
    {
        path: '/',
        element: <PrivateLayout />,
        children: [...WithoutLayoutRoutes, ...DashboardRoutes]
    }
]


